@font-face {
  font-family: outdoorFont;
  src: url('../Lumberjack.otf');
}

h1 {
  font-size: 400%;
}

h2 {
  font-size: 200%;
  text-align: center;
}

h3 {
  font-size: 150%;
  padding: 10px;
}

p {
  font-size: 120%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
}

.homepage {
  font-family: 'Ubuntu';
}

.content {
  max-width: 1000px;
  margin: auto; 
}

.break {
  border: rgb(5, 77, 41) solid 20px;
  max-width: 1600px;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

}

i, .icon {
  padding-left:10px;
  font-size: 120%
}

@media screen and (max-width: 1600px) {
  .content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

