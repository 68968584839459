#aboutMe {
  padding-top:40spx;
  text-align: center;
  margin:auto;
  /* max-width: 1400px; */
  width: 100%;
}

.intro {
  font-size: 200%;
  padding-top: 25px;
  padding-bottom:5px;
  text-decoration: underline;
}

.skills {
  padding-top: 20px;
}

.skillsColumnA {
  float: left;
  width: 33.33%;
  padding: 10px;
  padding-top: 40px;
}

.skillsColumnB {
  float: left;
  width: 33.33%;
  padding: 20px;
  border-right: #FFE600 solid 7px;
  border-left: #FFE600 solid 7px;
  margin-top: 20px;
  padding-bottom: 70px;

}
  
/* Clear floats after the columns */
.skillsRow:after {
  content: "";
  display: table;
  clear: both;
}
.icon {
  max-height: 20px;
}
.about {
  text-align: left;
  padding: 5px;
}
.me {
  display: block;
overflow: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: -35px;
  min-height: 100%;
	max-width: 1000px;
	background-color: rgb(5, 77, 41);
	border-radius: 10px;
	font-size: 300%;
	padding: 10px;
}

.outdoor {
	font-family: outdoorFont;
	float: left; 
  color: snow;  
}

.WD {
	font-family: 'Courier New', Courier, monospace;
	float: right;
}

.web{
	color: rgb(175, 182, 245)
}

.dev {
	color: rgb(141, 230, 148)	
}

@media screen and (max-width: 655px) {
  .skillsColumnA {
    display: block;
    width: 100%;
    border-bottom: #FFE600 solid 10px;
  }
  .skillsColumnB {
    display: block;
    width: 100%;
    border: none;
    border-bottom: #FFE600 solid 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 370px) {
  .intro {
    font-size: 160%;

  }
}
