.project {
  width: 100%;
  display: inline-block;
  border-bottom: #FFE600  solid 10px;
  /* margin-bottom: 15px; */
}

.noUnderline{
  text-decoration: none;
}

.strong {
  font-weight: bold;
  padding-top: 15px;
}

.projectDesc {
  padding-top: 10px;
}

.projectImgLeft {
  max-width: 40%;
  float: left;
  height: 375px;
  vertical-align: middle;
}
 
.projectAboutRight {
  max-width: 60%;
  float: right;
  padding-bottom: 15px;
}
 
.projectImgRight {
  max-width: 40%;
  float: right;
  height: 375px;
  vertical-align: middle;
}
  
.projectAboutLeft {
  max-width: 60%;
  float: left;
  padding-bottom: 15px;
}

.projectTitle {
  font-size: 250%;
}

.projectGitHubLink {
  font-size: 40%;
  padding-left: 20px;
  text-decoration: none;
}

.projectGitHubLink:hover {
  filter: invert(20%);
}

@media screen and (max-width: 1000px) {
  .projectImgLeft {
    max-width: 100%;
  } 
  .projectAboutRight {
    max-width: 100%;
  }
  .projectImgRight {
    max-width: 100%;
  }
  .projectAboutLeft {
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .projectImgRight {
    max-height: 200px;
    vertical-align: middle;
  }
  .projectImgLeft {
    max-height: 200px;
    vertical-align: middle;
  }
}