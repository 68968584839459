.smileyButton {
  position: fixed;
  right: calc(50% - 785px);
  top: 10px;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 4px 4px 8px lightslategray;
}

.smileyButton:hover {
  cursor: pointer;
}

@media (max-width: 1600px) {
  .smileyButton {
    right: 10px;
  }
}