html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  }

  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, caption {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
  }


body {
  /* font-family: Arial, Helvetica, sans-serif;     */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  
}








/* @media screen and (max-width: 400px) {
  body {
    background:  url('./background.svg') no-repeat center center fixed;
    background-size: cover;
    height: 100%;
  }
} */
@font-face {
  font-family: outdoorFont;
  src: url(/static/media/Lumberjack.7a932343.otf);
}

h1 {
  font-size: 400%;
}

h2 {
  font-size: 200%;
  text-align: center;
}

h3 {
  font-size: 150%;
  padding: 10px;
}

p {
  font-size: 120%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
}

.homepage {
  font-family: 'Ubuntu';
}

.content {
  max-width: 1000px;
  margin: auto; 
}

.break {
  border: rgb(5, 77, 41) solid 20px;
  max-width: 1600px;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

}

i, .icon {
  padding-left:10px;
  font-size: 120%
}

@media screen and (max-width: 1600px) {
  .content {
    padding-left: 15px;
    padding-right: 15px;
  }
}


#menuSlider {
  width: 100%;
  min-height: 50px;
  background-color: #FFE600;
  position: fixed;
  top: 120px;
  left: 0;
  transition: -webkit-transform .8s
              cubic-bezier(0, .25, 0, 1);
  transition: transform .8s
              cubic-bezier(0, .25, 0, 1);
  transition: transform .8s
              cubic-bezier(0, .25, 0, 1), -webkit-transform .8s
              cubic-bezier(0, .25, 0, 1);
  overflow: scroll;
  z-index: 1000;
}
 
#menuSlider.hide {
  -webkit-transform: translate3d(-110vw, 0, 0);
          transform: translate3d(-110vw, 0, 0);  
  overflow: hidden;
}
 
#menuSlider.show {
  -webkit-transform: translate3d(0vw, 0, 0);
          transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}
 
#menuSlider .menuItems span {
  color: rgb(5, 77, 41);
  margin-left: 15px;
  text-decoration: none;
  float: right;
  padding: 10px;
}
 
#menuSlider .menuItems span:hover {
  text-decoration: underline;
  cursor: pointer;
}


@media (min-width: 1600px) {
  #menuSlider {
    max-width: calc(((100% - 1600px)/2) + 1600px);
  }
}

@media screen and (max-width: 520px) {
  .menuItem {
    display: block;
    width: 100%;
  }
}
#aboutMe {
  padding-top:40spx;
  text-align: center;
  margin:auto;
  /* max-width: 1400px; */
  width: 100%;
}

.intro {
  font-size: 200%;
  padding-top: 25px;
  padding-bottom:5px;
  text-decoration: underline;
}

.skills {
  padding-top: 20px;
}

.skillsColumnA {
  float: left;
  width: 33.33%;
  padding: 10px;
  padding-top: 40px;
}

.skillsColumnB {
  float: left;
  width: 33.33%;
  padding: 20px;
  border-right: #FFE600 solid 7px;
  border-left: #FFE600 solid 7px;
  margin-top: 20px;
  padding-bottom: 70px;

}
  
/* Clear floats after the columns */
.skillsRow:after {
  content: "";
  display: table;
  clear: both;
}
.icon {
  max-height: 20px;
}
.about {
  text-align: left;
  padding: 5px;
}
.me {
  display: block;
overflow: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: -35px;
  min-height: 100%;
	max-width: 1000px;
	background-color: rgb(5, 77, 41);
	border-radius: 10px;
	font-size: 300%;
	padding: 10px;
}

.outdoor {
	font-family: outdoorFont;
	float: left; 
  color: snow;  
}

.WD {
	font-family: 'Courier New', Courier, monospace;
	float: right;
}

.web{
	color: rgb(175, 182, 245)
}

.dev {
	color: rgb(141, 230, 148)	
}

@media screen and (max-width: 655px) {
  .skillsColumnA {
    display: block;
    width: 100%;
    border-bottom: #FFE600 solid 10px;
  }
  .skillsColumnB {
    display: block;
    width: 100%;
    border: none;
    border-bottom: #FFE600 solid 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 370px) {
  .intro {
    font-size: 160%;

  }
}

#projects {
  margin:auto;
  /* max-width: 1400px; */
  width: 100%;
}
.projectsTitle {
  text-align: center;
  text-decoration: underline;
  margin-bottom: 30px;

}



.project {
  width: 100%;
  display: inline-block;
  border-bottom: #FFE600  solid 10px;
  /* margin-bottom: 15px; */
}

.noUnderline{
  text-decoration: none;
}

.strong {
  font-weight: bold;
  padding-top: 15px;
}

.projectDesc {
  padding-top: 10px;
}

.projectImgLeft {
  max-width: 40%;
  float: left;
  height: 375px;
  vertical-align: middle;
}
 
.projectAboutRight {
  max-width: 60%;
  float: right;
  padding-bottom: 15px;
}
 
.projectImgRight {
  max-width: 40%;
  float: right;
  height: 375px;
  vertical-align: middle;
}
  
.projectAboutLeft {
  max-width: 60%;
  float: left;
  padding-bottom: 15px;
}

.projectTitle {
  font-size: 250%;
}

.projectGitHubLink {
  font-size: 40%;
  padding-left: 20px;
  text-decoration: none;
}

.projectGitHubLink:hover {
  -webkit-filter: invert(20%);
          filter: invert(20%);
}

@media screen and (max-width: 1000px) {
  .projectImgLeft {
    max-width: 100%;
  } 
  .projectAboutRight {
    max-width: 100%;
  }
  .projectImgRight {
    max-width: 100%;
  }
  .projectAboutLeft {
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .projectImgRight {
    max-height: 200px;
    vertical-align: middle;
  }
  .projectImgLeft {
    max-height: 200px;
    vertical-align: middle;
  }
}
#connect {
  width: 100%;
  text-align: center;
  font-size: 120%;
}

#connect > * {
  padding: 10px;
}
 
.connectTitle {
  text-decoration: underline;
  padding: 15px;
}

input {
  background:rgba(0,0,0,0);
  border:none;
  font-size: 150%;
  padding: 10px;
  padding-left: 60px;
}

input:hover {
  text-decoration: underline;
  cursor: pointer;
}

.socialMediaIcons {
  height: 145px;
  margin-bottom: 10px;
}

.socialMediaIcon {
  height: 100%;
  padding:20px;
}

.socialMediaIcon:hover {
  -webkit-filter: invert(20%);
          filter: invert(20%);
}

.smileyButton {
  position: fixed;
  right: calc(50% - 785px);
  top: 10px;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 4px 4px 8px lightslategray;
}

.smileyButton:hover {
  cursor: pointer;
}

@media (max-width: 1600px) {
  .smileyButton {
    right: 10px;
  }
}
.footer{
  padding: 1rem;
  background-color: rgb(5, 77, 41);
  text-align: center;
  color: white;
}



.homepageImage {
  background: url(/static/media/backGround1.7814b5fc.jpg); 
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center; 
  width: 100%;
  max-width: 1600px;
  min-height: 500px;
  margin: auto;
  border-bottom: #FFE600 solid 20px;
  border-radius: 5px;		
}

.headerContent {
  min-height: 500px;
  max-width: 1400px;
  margin: auto;
  position: relative;
}

.samHeader {
  position: absolute;
  bottom: 10%;
  left: 10%;
  color: white;
  text-shadow: 4px 4px black;
}


@media screen and (max-width: 650px) {
  .samHeader {
    left: 5%;
  }
  .homepageImage {
    /* background: url('../images/smallBG.jpg');  */
  }
}


