.homepageImage {
  background: url('../images/backGround1.jpg'); 
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center; 
  width: 100%;
  max-width: 1600px;
  min-height: 500px;
  margin: auto;
  border-bottom: #FFE600 solid 20px;
  border-radius: 5px;		
}

.headerContent {
  min-height: 500px;
  max-width: 1400px;
  margin: auto;
  position: relative;
}

.samHeader {
  position: absolute;
  bottom: 10%;
  left: 10%;
  color: white;
  text-shadow: 4px 4px black;
}


@media screen and (max-width: 650px) {
  .samHeader {
    left: 5%;
  }
  .homepageImage {
    /* background: url('../images/smallBG.jpg');  */
  }
}

