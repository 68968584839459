#connect {
  width: 100%;
  text-align: center;
  font-size: 120%;
}

#connect > * {
  padding: 10px;
}
 
.connectTitle {
  text-decoration: underline;
  padding: 15px;
}

input {
  background:rgba(0,0,0,0);
  border:none;
  font-size: 150%;
  padding: 10px;
  padding-left: 60px;
}

input:hover {
  text-decoration: underline;
  cursor: pointer;
}

.socialMediaIcons {
  height: 145px;
  margin-bottom: 10px;
}

.socialMediaIcon {
  height: 100%;
  padding:20px;
}

.socialMediaIcon:hover {
  filter: invert(20%);
}
