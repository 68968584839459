#menuSlider {
  width: 100%;
  min-height: 50px;
  background-color: #FFE600;
  position: fixed;
  top: 120px;
  left: 0;
  transition: transform .8s
              cubic-bezier(0, .25, 0, 1);
  overflow: scroll;
  z-index: 1000;
}
 
#menuSlider.hide {
  transform: translate3d(-110vw, 0, 0);  
  overflow: hidden;
}
 
#menuSlider.show {
  transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}
 
#menuSlider .menuItems span {
  color: rgb(5, 77, 41);
  margin-left: 15px;
  text-decoration: none;
  float: right;
  padding: 10px;
}
 
#menuSlider .menuItems span:hover {
  text-decoration: underline;
  cursor: pointer;
}


@media (min-width: 1600px) {
  #menuSlider {
    max-width: calc(((100% - 1600px)/2) + 1600px);
  }
}

@media screen and (max-width: 520px) {
  .menuItem {
    display: block;
    width: 100%;
  }
}